import PageComponent from '../../common/component/page-component';

class RandomOrder extends PageComponent {

	constructor({
					root,
					element
				}) {
		super({root: root, element: element});
	}


	prepare() {
		// apply order:x style with random x to all children of this.element
		const children = this.element.children;
		for (const child of children) {
			child.style.order = Math.floor(Math.random() * 100); // Generate a random order value between 0 and 99
		}
	}

}

export default RandomOrder;
